
import { mapActions,mapGetters } from 'vuex';
// import JobFormErrorModal from '@/modules/jobs/components/job-form-error-modal.vue';
import FacilitiesListTable from '@/modules/facilities/components/facilities-list-table.vue';
// import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/database';
// import {i18n} from '@/i18n'
import i18n from '@/vueI18n';

export default {
    data(){
        return{
            // prescritptionsOwner:true
            modalErrorVisible:false,
            dialogErrorVisible:false,
            currentMonth:undefined,
            currentYear:undefined,
            propertyMaxCount: undefined,
            addedPropertyInMonth: 0,
            userPropertiesInMonth:[],
            userProperty: {},
            canNotAdd:false,
            monthNames : ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
            ]
        }
    },
    components:{
        [FacilitiesListTable.name]: FacilitiesListTable
    },
    watch:{
        lang(){
            document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.facilities.myFacilities') 
        },
        async userListener(newval){
            if(this.userListener){
                this.addMoreFacilities(newval) 
            }
        }
    },
    computed:{
        lang(){
            // debugger    
            return i18n.locale
        },
        ...mapGetters({
            currentUser: 'auth/currentUser',
            user: 'facilities/list/user',
            userListener: 'auth/userListener',
        })
    },
    methods:{
        addNewItem(){
            if(this.canNotAdd){
                // this.modalVisible = true
                // this.dialogVisible = true
                this.modalErrorVisible = true
                this.dialogErrorVisible = true
            }
            else{
                this.$router.push('add-facility')
            }            
        },
        closeErrorModal(){
            this.dialogErrorVisible = false;
        setTimeout(() => {
            this.modalErrorVisible = false;
        }, 500);
        },
        ...mapActions({
            setHeaderBg:'layout/setHeaderBg',
            setFooter:'layout/setFooter',
            setCurrentRoute: 'facilities/list/setCurrentRoute',
            doFetchUser: 'facilities/list/doFetchUser',
        setLoadingAddMoreFacilities: 'facilities/list/setLoadingAddMoreFacilities',

        }),
        i18n(key, args) {
            return this.$t(key, args);
        },
        async addMoreFacilities(userListener){
            this.setLoadingAddMoreFacilities(true)
            this.userProperty = userListener.property
            this.addedPropertyInMonth = 0
            var today = new Date();
            if(this.userProperty || this.userProperty != undefined){            
                for (const [key] of Object.entries(this.userProperty)) {
                    var firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                    var lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    if(key >= firstDay && key < lastDay){
                        this.addedPropertyInMonth = this.addedPropertyInMonth + 1
                    }
                }
                await firebase.database().ref('setting/property').on('value',(snapshot) => {
                    this.propertyMaxCount = snapshot.val()
                    if(this.addedPropertyInMonth == this.propertyMaxCount || this.addedPropertyInMonth > this.propertyMaxCount){
                        this.canNotAdd = true
                    }
                    else{
                        this.canNotAdd = false
                    }
                    this.setLoadingAddMoreFacilities(false)
                });
            }
            else{
                this.setLoadingAddMoreFacilities(false)
            }
            }
    },

  
    async created(){

        document.title =
                this.i18n('app.title') +
                ' | ' +
                this.i18n('drugDeal.facilities.myFacilities') 


        this.setHeaderBg('bg-accent')
        this.setFooter(true)
        this.setCurrentRoute(this.$router.currentRoute.name)
        if(this.userListener){
            this.addMoreFacilities(this.userListener)
        }  
    },
    destroyed(){
        this.setLoadingAddMoreFacilities(true)
    }

}
